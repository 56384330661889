<!--Pagina de manifiesto-->
<template>
    <v-container fill-height fluid class="black d-flex align-stretch justify-center fondo">
    <v-row class=""  justify="center" no-gutters>        
        <v-col cols="12" md="11" class="py-0 px-0" >
            <menus/>
            <v-row class="">
                <v-col cols="0" md="2" class=" fondoi d-none d-md-flex">

                </v-col>
                <v-col cols="12" md="8" class="">
                        <v-card  elevation="0" outline="0"  class="justify-center mx-5 text-center mx-md-auto view_h" color="rgba(0,0,0,0)">
                            <!--Video de YouTube-->
                            <v-responsive :aspect-ratio="16/9" align="center">    
                                <iframe width="100%" height="100%" src="https://www.youtube.com/embed/D914ONQx3V4" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
                            </v-responsive>
                        <v-card-actions>
                            
                            <v-btn rounded min-width="300px" color="becks" class="mx-auto mt-10"  @click="cambiarP('AgregarUnaFoto')">
                                Continuar
                            </v-btn>  
                        </v-card-actions>
                        </v-card>
                </v-col>
                <v-col cols="0" md="2" class="fondod d-none d-md-flex">

                </v-col>
            </v-row>
        </v-col>
    </v-row>
    </v-container>
</template>
<script>
    import menus from "../components/menus.vue"
    import axios from 'axios'

    export default {
        data: () => ({
            
        }),
        methods: {
            cambiarP(lugar){
                axios.get('/').then(response => (
                    this.$router.push({name: lugar})
                ))
                .catch(
                    error => console.log('Hubo un error: ' + error)
                )
            },
        checkCookie(){
            axios
                .post("check_cookie.php", {
                
                })
                .then(function (response) {
                //   console.log(response.data.message)
        
                  if(response.data.message != 'galleta'){
                    window.location.replace("/Edad");
                  }
        
                })
                .catch((error) => {
                    // console.log(error)
                });
            }
        },
        computed:{
            
        },
        created() {
            this.checkCookie()
        },
        name: 'Age',
        components: {
            menus
        },
    }
</script>
<style>
    @media (max-width: 960px){
        .fondo{
            background-image: url(../assets/manifiestoMobileFinal.jpg);
            background-size: 100% 90%;
            background-position-y:bottom;
            background-repeat: no-repeat;
            
        }
        
    }
    .view_h{ 
        min-height: 50vh;
    }
    .fondod{
        background-image: url(../assets/ManifiestoDerecha.png);
        z-index: 10;
        background-size: contain;
        background-position-x:right;
        background-repeat: no-repeat;
        
    }
    .fondoi{
        background-image: url(../assets/ManifiestoIzquierdaFinal.png);
        background-size: contain;
        background-position-x: left;
        background-repeat: no-repeat;
        
    }
</style>